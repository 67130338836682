import "./App.css";
import projectDetails from "./projectDetails.json";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import Header from "./Header";
import Use_Window_Dimensions from "./Use_Window_Dimensions";

const GATrackingId = "G-N4W3ZYFKW4";
ReactGA.initialize(GATrackingId);

function App() {
  const { Window_Width, Window_Height } = Use_Window_Dimensions();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  });

  return (
    <div className="homepage">
      <Header Window_Width={Window_Width}></Header>
      <div className="content">
        <div className="about section">
          <h1 className="h2">About </h1>
          <div>
            <p>
              The Transport Research and Innovation Board (TRIB) brings together representatives from key organisations that fund and carry out
              research and innovation in the UK, as well as government departments with an interest in transport.
              <br />
              For more information about TRIB, please{" "}
              <a href="https://www.gov.uk/government/groups/transport-research-and-innovation-board">see more.</a>
              <br />
              <br />
              The objectives of the board are to:
            </p>
            <ul>
              <li>join-up leaders: identify priority areas; areas with most promise and where to focus effort</li>
              <li>join-up activities: co-ordinate activities to meet shared strategic needs, improving value from existing and planned work</li>
              <li>leverage funding: enable the funding of larger-scale, more ambitious projects</li>
              <li>facilitate demonstrators: accelerating ideas and testing them in practice to bring them to market sooner</li>
              <li>engage globally: seize an advantage in the rapidly evolving and economically strategic transport technology sector</li>
              <li>
                create a line of sight to government priorities: understand the priorities of the Secretaries of State of the Department for Transport
                (DfT) and other relevant departments.
              </li>
            </ul>
          </div>
        </div>
        <div className="projects section">
          <h1 className="h2">Projects</h1>
          <div className="projectList">
            {projectDetails
              ? projectDetails.projects.map((project, projectId) => (
                  <a className="project" href={project.link} key={projectId}>
                    <div>
                      <img src={require(`./thumbnails/${project.img}`)} alt=""></img>
                    </div>
                    <div className="details">
                      <h1 className="h3">{project.title}</h1>
                      <p className="link" href={project.link}>
                        {project.link}
                      </p>
                      <p>{project.description}</p>
                    </div>
                  </a>
                ))
              : null}
          </div>
        </div>
        <div className="members section">
          <h1 className="h2">Our Members</h1>
          <div className="logos">
            <img className="member" src={require("./logos/DfT_3298_AW (002).png")} id="DfT" alt="DfT logo"></img>
            <img className="member" src={require("./logos/CPC_Logo_RGB_green.png")} id="CPC" alt="CPC logo"></img>
            <img className="member" src={require("./logos/MCA.png")} id="MCA" alt="Maritime & Coastguard Agency logo"></img>
            <img className="member" src={require("./logos/Network_Rail.jpg")} id="Network Rail" alt="Network Rail logo"></img>
            <img className="member" src={require("./logos/UKRI-Logo_Horiz-RGB.png")} id="UKRI" alt="UKRI logo"></img>
            <img className="member" src={require("./logos/HVM_Catapult.jpg")} id="HVM Catapult" alt="HVM Catapult logo"></img>
            <img className="member" src={require("./logos/NDTP-logo-v3-HM Gov-Blue.jpg")} id="NDTP" alt="NDTP logo"></img>
            <img className="member" src={require("./logos/National_Highways.png")} id="National Highways" alt="National Highways logo"></img>
            <img className="member" src={require("./logos/Adept_Master_Logo_RGB_HR.png")} id="ADEPT" alt="ADEPT logo"></img>
            <img className="member" src={require("./logos/HS2_Logo.png")} id="HVM Catapult" alt="HS2 logo"></img>
            <img className="member" src={require("./logos/Innovate_UK.png")} id="Innovate UK" alt="Innovate UK logo"></img>
            <img className="member" src={require("./logos/UKRI_EPSR_Council-Logo_Horiz-RGB.png")} id="EPSRC" alt="EPSRC logo"></img>

            <img
              className="member"
              src={require("./logos/DSIT_Colour_Main.png")}
              id="Department for Science, Innovation & Technology"
              alt="DSIT logo"
            ></img>
            <img className="member" src={require("./logos/RSSB_MASTER_LOGO_DIGITAL_LR.png")} id="RSSB Placeholder" alt="RSSB Placeholder logo"></img>
            <img
              className="member"
              src={require("./logos/__sitelogo__Hi Res Logo.png")}
              id="Aerospace Technology Institute"
              alt="Aerospace Technology Institute logo"
            ></img>
          </div>
        </div>
      </div>
      <footer className="footer">
        <div className="firstColumn">
          <h1 className="h4">
            <a href="mailto:contact@trib.org.uk" className="p1">
              contact@trib.org.uk
            </a>
          </h1>
        </div>
      </footer>
    </div>
  );
}

export default App;

import { useState, useEffect } from "react";
function Get_Window_Dimensions() {
  return {
    Window_Width: window.innerWidth,
    Window_Height: window.innerHeight,
  };
}
export default function Use_Window_Dimensions() {
  const [Window_Dimensions, Set_Window_Dimensions] = useState(Get_Window_Dimensions());

  useEffect(() => {
    function Handle_Resize() {
      Set_Window_Dimensions(Get_Window_Dimensions());
    }

    window.addEventListener("resize", Handle_Resize);
    return () => window.removeEventListener("resize", Handle_Resize);
  }, []);

  return Window_Dimensions;
}

import React, { useEffect, useState, useRef } from "react";
import * as accessibilityIcon from "./icons/Accessibility.png";
import "./Header.css";
const Header = (props) => {
  const [Font_Size, Set_Font_Size] = useState(1);
  const [Line_Height, Set_Line_Height] = useState(1.1);
  const [Hue_Value, Set_Hue_Value] = useState(0);
  const [Contrast_Value, Set_Contrast_Value] = useState(1);
  const modalRef = useRef(null);

  useEffect(() => {
    const root = document.querySelector(":root");
    root.style.setProperty("--relative_font_size", Font_Size);
  }, [Font_Size]);
  useEffect(() => {
    const root = document.querySelector(":root");
    root.style.setProperty("--relative_line_height", Line_Height);
  }, [Line_Height]);
  useEffect(() => {
    const root = document.querySelector(":root");
    root.style.setProperty("--hue_rotation", `${Hue_Value}deg`);
  }, [Hue_Value]);
  useEffect(() => {
    const root = document.querySelector(":root");
    root.style.setProperty("--contrast_value", Contrast_Value);
  }, [Contrast_Value]);

  return (
    <header className="header">
      <dialog data-modal ref={modalRef} className="modal">
        <button
          onClick={() => {
            modalRef.current.close();
          }}
        >
          <i className="close icon"></i>
        </button>
        <div className="accessibility_switch">
          <p>Font-Size:</p>

          <select
            class="ui dropdown"
            onChange={(event) => {
              Set_Font_Size(event.target.value);
            }}
            value={Font_Size}
          >
            <option value="0.75">Small</option>
            <option value="1">Regular</option>
            <option value="1.25">Large</option>
          </select>
        </div>
        <div className="accessibility_switch">
          <p>Line Height:</p>

          <select
            class="ui dropdown"
            onChange={(event) => {
              Set_Line_Height(event.target.value);
            }}
            value={Line_Height}
          >
            <option value="1">Small</option>
            <option value="1.1">Regular</option>
            <option value="1.25">Large</option>
            <option value="1.5">Extra Large</option>
          </select>
        </div>
        <div class="accessibility_switch">
          <p>Contrast :</p>
          <input
            type="range"
            min={0}
            max={2}
            step={0.1}
            value={Contrast_Value}
            onChange={(event) => {
              Set_Contrast_Value(event.target.value);
            }}
            class="slider"
            id="myRange"
          />
        </div>
        <div class="accessibility_switch">
          <p>Hue Rotation:</p>
          <input
            type="range"
            min={0}
            max={360}
            step={10}
            value={Hue_Value}
            onChange={(event) => {
              Set_Hue_Value(event.target.value);
            }}
            class="slider"
            id="myRange"
          />
        </div>
      </dialog>
      <div className="borderedContent topbar">
        <h1 className="logo">{props.Window_Width > 1000 ? "Transport Research and Innovation Board" : "TRIB"}</h1>
        <div className="navigation">
          <a
            onClick={() => {
              const content = document.getElementsByClassName("about");
              if (content[0] != undefined)
                window.scrollTo({ top: content[0].getBoundingClientRect().top + window.pageYOffset - 80, behavior: "smooth" });
            }}
          >
            About
          </a>
          <a
            onClick={() => {
              const content = document.getElementsByClassName("projects");
              if (content[0] != undefined)
                window.scrollTo({ top: content[0].getBoundingClientRect().top + window.pageYOffset - 80, behavior: "smooth" });
            }}
          >
            Projects
          </a>
          <a
            onClick={() => {
              const content = document.getElementsByClassName("members");
              if (content[0] != undefined)
                window.scrollTo({ top: content[0].getBoundingClientRect().top + window.pageYOffset - 80, behavior: "smooth" });
            }}
          >
            Our members
          </a>
          <button
            onClick={() => {
              modalRef.current.showModal();
            }}
          >
            <img src={accessibilityIcon.default}></img>
          </button>
        </div>
      </div>
    </header>
  );
};
export default Header;
